// src/Pantry.js
import React, { useRef, useState, useEffect } from "react";
import logo from "../Assets/cookwhite1.png"; 

import cookvid from "../Assets/cookingbid.mp4"
import "./MainPage.css";


const MainPage = ({}) => {
  const videoRef = useRef(null);

  useEffect(() => {
    // Ensure video is muted, autoplayed, and loops when component mounts
    if (videoRef.current) {
      videoRef.current.muted = true;
      videoRef.current.autoplay = true;
      videoRef.current.loop = true;
    }
  }, []);
  
  return (
    <div className="home-wrapper" id="Home">
     
       <section id="mainpage">
      
          <div class="section__pic-container">
              <video ref={videoRef} >
                <source src={cookvid} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>

            <div class="section__text">

              <h1 class="title">Get Started</h1>
              <p class="section__text__p1">Welcome! Let Us Cook is a responsive web application utilizing artificial intelligence to assist you in sharpening
              your culinary skills and recipes. To begin, simply input your desired ingredients in our "Pantry" section
              found below. Once you are satisfied, head on over to the "Generate" section to adjust your preferences. You may now
              generate your recipes! Moreover, in the pantry section, you can download the current state of your pantry and upload it later
              for your convenience. Enjoy!</p>
            </div>
        
          

    </section>
    </div>
  );
};

export default MainPage;
